import { Col, Row } from 'antd'
import { FAQ as FAQComponent } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'

import LayoutBase from '../components/LayoutBase'
import useLanguage from '../hooks/useLanguage'
import { localizedRoutes } from '../utils'

interface FAQProps {
  token: string
}

const FAQ: FunctionComponent<FAQProps> = ({ token }) => {
  const { language } = useLanguage()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.faq}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24}>
          <FAQComponent baseUrl={window.brandConfig.baseUrl} token={token} language={language} showForm />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default FAQ
