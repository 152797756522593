import { FunctionComponent } from 'react'
import LayoutBase from '../components/LayoutBase.tsx'
import { Helmet } from 'react-helmet-async'
import { localizedRoutes } from '../utils'
import { Col, Row } from 'antd'
import { AddendumConsumptionChange } from '@myportal/ecedo-myportal-frontend-components'
import useLanguage from '../hooks/useLanguage.ts'
import { useSelector } from 'react-redux'
import { RootState } from '../store/reducers'
import { OrganizationState } from '../store/reducers/organizationReducer.ts'
import useDaughterOrganization from '../hooks/useDaughterOrganization.ts'

interface ConsumptionChangeProps {
  token: string
}

const DaughterConsumptionChange: FunctionComponent<ConsumptionChangeProps> = ({ token }) => {
  const { language } = useLanguage()
  const { daughterOrganization } = useSelector<RootState, OrganizationState>((state) => state.organization)

  useDaughterOrganization()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.addendumGreening}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={24}>
          <AddendumConsumptionChange
            baseUrl={window.brandConfig.baseUrl}
            token={token}
            language={language}
            organizationId={daughterOrganization?.organizationId}
          />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default DaughterConsumptionChange
