import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { Grid, Layout, Drawer } from 'antd'

import camelcase from 'camelcase'
import { OrganizationBreadcrumb, Cookie } from '@myportal/ecedo-myportal-frontend-components'
import React, { FunctionComponent, ReactNode, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { breakpoints } from '../config/responsive'
import useLanguage from '../hooks/useLanguage'
import { RootState } from '../store/reducers'
import { AuthState } from '../store/reducers/authReducer'
import { MenuState, toggleMenu, setMenu } from '../store/reducers/menuReducer'
import { OrganizationState, setBreadcrumb, setDaughterOrganization } from '../store/reducers/organizationReducer'
import translations from '../translation'
import { getLocalizedRoutesForHistoryPush } from '../utils'
import { defaultMenuList, parentMenuList } from './MenuList'
import NavBar from './Navbar.tsx'

const { Header, Sider, Content } = Layout
const { useBreakpoint } = Grid

const FullLayout = styled(Layout)`
    .ant-layout {
        background: url('/brand/background.svg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    min-height: 100vh;
    flex-direction: row;

    .desktopMenu {
        display: none;
    }

    @media only screen and (min-width: 768px) {
        .desktopMenu {
            display: block;
        }
    }

    @media only screen and (min-width: 768px) {
        .mobileMenu {
            display: none;
        }

        .mobileMenu {
            display: block;
        }
`

const MobileDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 300px !important;
  }

  .ant-drawer-body {
    padding: 0 !important;
  }
`

const ContentHeader = styled(Header)`
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 0 0 6px;
  padding: 12px 18px;
  height: auto;
  line-height: initial;
  @media (min-width: ${breakpoints.md}) {
    padding: 0 24px;
    height: 64px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 48px;
  }
  > div,
  > ul {
    margin-left: auto;
  }
`

const MainContent = styled(Content)`
  padding: 0 18px 9px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0 24px 12px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 48px 24px;
  }
`

const StyledSider = styled(Sider)`
  background: #ffffff;
  .ant-layout-sider-children {
    position: relative;
    .ant-menu-inline {
      border: 0;
      .ant-menu-item {
        padding: 10px 0;
        white-space: normal;
        height: min-content;
        .ant-menu-title-content {
          line-height: 20px;
        }
      }
    }
  }
`

interface LayoutBaseProps {
  children?: ReactNode
}

const LayoutBase: FunctionComponent<LayoutBaseProps> = ({ children }) => {
  const { language } = useLanguage()
  const { token, groups, userId, organizationId } = useSelector<RootState, AuthState>((state) => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { open } = useSelector<RootState, MenuState>((state) => state.menu)
  const { parent, selected, pendingOrganization, pendingDaughterOrganizationList, daughterOrganization, breadcrumb, connectionGroup } = useSelector<
    RootState,
    OrganizationState
  >((state) => state.organization)
  const { md, lg } = useBreakpoint()

  const [isToggled, setToggled] = useState(false)

  const handleMenuToggle = useCallback(() => {
    dispatch(toggleMenu())
  }, [dispatch])

  const handleMobileDrawer = useCallback(() => {
    setToggled(!isToggled)
  }, [isToggled])

  const mobileDrawerOnClose = useCallback(() => {
    setToggled(false)
  }, [])

  const handleMenuOnBreakpoint = useCallback(
    (broken: boolean): void => {
      !open && dispatch(setMenu(broken))
    },
    [dispatch, open]
  )

  const handleClose = useCallback(() => {
    navigate('/')
    dispatch(setBreadcrumb({ active: 1 }))
  }, [dispatch, navigate])

  const mainMenuList = parent ? parentMenuList(language) : defaultMenuList(language, groups)

  const handleDaughterSelect = useCallback(
    (organization: { organizationReference: string }) => {
      navigate(`${getLocalizedRoutesForHistoryPush('general')}/${organization?.organizationReference}`)
      dispatch(setDaughterOrganization(organization))
    },
    [dispatch, navigate]
  )

  const handleConnections = useCallback(() => {
    navigate(`${getLocalizedRoutesForHistoryPush('connections')}/${daughterOrganization?.organizationReference}`)
    dispatch(setBreadcrumb({ active: 3 }))
  }, [daughterOrganization?.organizationReference, dispatch, navigate])

  const handleMoreInfo = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      navigate(getLocalizedRoutesForHistoryPush('privacyPolicy'))
    },
    [navigate]
  )

  return (
    <FullLayout>
      <MobileDrawer className="mobileMenu" open={isToggled} onClose={mobileDrawerOnClose} placement="left">
        <NavBar
          selected={selected}
          token={token}
          userId={userId}
          organizationId={organizationId}
          pendingOrganization={pendingOrganization}
          pendingDaughterOrganizationList={pendingDaughterOrganizationList}
          mainMenuList={mainMenuList}
          daughterOrganization={daughterOrganization}
          handleClose={handleClose}
          groups={groups}
        />
      </MobileDrawer>
      <StyledSider
        className="desktopMenu"
        trigger={null}
        collapsible
        collapsed={open}
        collapsedWidth={md ? 80 : 0}
        breakpoint="lg"
        onBreakpoint={handleMenuOnBreakpoint}
      >
        <NavBar
          selected={selected}
          token={token}
          userId={userId}
          organizationId={organizationId}
          pendingOrganization={pendingOrganization}
          pendingDaughterOrganizationList={pendingDaughterOrganizationList}
          mainMenuList={mainMenuList}
          daughterOrganization={daughterOrganization}
          handleClose={handleClose}
          groups={groups}
        />
      </StyledSider>
      <Layout>
        <ContentHeader>
          {React.createElement(open ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: !lg ? handleMobileDrawer : handleMenuToggle,
          })}
          <OrganizationBreadcrumb
            baseUrl={window.brandConfig.baseUrl}
            token={token}
            language={language}
            active={breadcrumb.active}
            page={translations[language as keyof typeof translations][camelcase(breadcrumb.page || '') as keyof typeof translations.en]}
            daughterOrganization={daughterOrganization as any}
            connectionGroup={connectionGroup as any}
            parentCallback={handleClose}
            daughterCallback={handleDaughterSelect}
            connectionCallback={handleConnections}
          />
        </ContentHeader>
        <MainContent>{children}</MainContent>
      </Layout>
      <Cookie
        handleMoreInfo={handleMoreInfo}
        googleTagManagerId={window.brandConfig.googleTagManagerId}
        googleAnalyticsId={window.brandConfig.googleAnalyticsId}
        cookieExpires={window.brandConfig.cookieExpires}
        language={language}
      />
    </FullLayout>
  )
}

export default LayoutBase
