import { FunctionComponent } from 'react'
import LayoutBase from '../components/LayoutBase.tsx'
import { Helmet } from 'react-helmet-async'
import { localizedRoutes } from '../utils'
import { Col, Row } from 'antd'
import { AddendumGreening } from '@myportal/ecedo-myportal-frontend-components'
import useLanguage from '../hooks/useLanguage.ts'

interface GreeningProps {
  token: string
}

const Greening: FunctionComponent<GreeningProps> = ({ token }) => {
  const { language } = useLanguage()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.addendumGreening}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={24}>
          <AddendumGreening baseUrl={window.brandConfig.baseUrl} token={token} language={language} />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default Greening
