import { Col, Row } from 'antd'
import { ChangePassword, ContactInfo, FAQ, FinancialInfo, GeneralInfo } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'

import LayoutBase from '../components/LayoutBase'
import useLanguage from '../hooks/useLanguage'
import { localizedRoutes } from '../utils'

interface MyData {
  token: string
}

const MyData: FunctionComponent<MyData> = ({ token }) => {
  const { language } = useLanguage()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.myData}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={12}>
          <ContactInfo baseUrl={window.brandConfig.baseUrl} token={token} language={language} canChangeEmail={window.brandConfig.canChangeEmail} />
          <br />
          <FinancialInfo
            baseUrl={window.brandConfig.baseUrl}
            token={token}
            language={language}
            showFinancialDayInputs={window.brandConfig.showFinancialDayInputs}
          />
        </Col>
        <Col xs={24} md={12} lg={12} xl={8}>
          <GeneralInfo baseUrl={window.brandConfig.baseUrl} token={token} language={language} />
          <br />
          <ChangePassword language={language} shadow />
          <br />
          <FAQ baseUrl={window.brandConfig.baseUrl} token={token} language={language} page="myData" />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default MyData
