import { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import useAuth from './hooks/useAuth'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import InvoiceList from './pages/InvoiceList'
import Charts from './pages/Charts'
import MyData from './pages/Data'
import ContractList from './pages/ContractList'
import NotFound from './pages/NotFound'
import MeterReadings from './pages/MeterReadings'
import FAQ from './pages/FAQ'
import OrganizationRegistration from './pages/OrganizationRegistration'
import DaughterData from './pages/DaughterData'
import DaughterConnections from './pages/DaughterConnections'
import DaughterFinancial from './pages/DaughterFinancial'
import DaughterMeterReadings from './pages/DaughterMeterReadings'
import DaughterContracts from './pages/DaughterContracts'
import MeterReadingsPublic from './pages/MeterReadingsPublic'
import MyLocationsPage from './pages/MyLocations'
import InvoiceDetails from './pages/InvoiceDetails'
import NewPassword from './pages/NewPassword'
import AccountCreation from './pages/AccountCreation'
import ResendCodePage from './pages/ResendCode'
import TerminationFee from './pages/TerminationFee'
import TerminationFeeCalculationPage from './pages/TerminationFeeCalculation'
import ReferenceProposition from './pages/ReferencePropostion'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ForgotFinish from './pages/ForgotPasswordFinish'
import ForgotStart from './pages/ForgotPasswordStart'
import { getLocalizedRoutes } from './utils'
import Toolset from './pages/Admin.tsx'
import { AuthState } from './store/reducers/authReducer'
import { RootState } from './store/reducers'
import ConfirmAccount from './pages/ConfirmAccount'
import Addendum from './pages/Addendum'
import Greening from './pages/Greening.tsx'
import EntityChange from './pages/EntityChange.tsx'
import ConsumptionChange from './pages/ConsumptionChange.tsx'
import DaughterAddendum from './pages/DaughterAddendum.tsx'
import DaughterConsumptionChange from './pages/DaughterConsumptionChange.tsx'
import DaughterGreening from './pages/DaughterGreening.tsx'
import DaughterEntityChange from './pages/DaughterEntityChange.tsx'
import Documents from './pages/Documents.tsx'
import Moving from './pages/Moving.tsx'
import DaughterMoving from './pages/DaughterMoving.tsx'

const Router: FunctionComponent = () => {
  const { token } = useAuth()
  const { groups } = useSelector<RootState, AuthState>((state) => state.auth)

  return (
    <Routes>
      {getLocalizedRoutes('daughterData').map((path: string) => (
        <Route key={path} path={path} element={<DaughterData token={token} />} />
      ))}
      {getLocalizedRoutes('daughterConnections').map((path: string) => (
        <Route key={path} path={path} element={<DaughterConnections token={token} />} />
      ))}
      {getLocalizedRoutes('daughterFinancial').map((path: string) => (
        <Route key={path} path={path} element={<DaughterFinancial token={token} />} />
      ))}
      {getLocalizedRoutes('daughterMeterReadings').map((path: string) => (
        <Route key={path} path={path} element={<DaughterMeterReadings token={token} />} />
      ))}
      {getLocalizedRoutes('daughterContracts').map((path: string) => (
        <Route key={path} path={path} element={<DaughterContracts token={token} />} />
      ))}
      {getLocalizedRoutes('meterReadings').map((path: string) => (
        <Route key={path} path={path} element={<MeterReadings token={token} />} />
      ))}
      {getLocalizedRoutes('meterReadingsPublic').map((path: string) => (
        <Route key={path} path={path} element={<MeterReadingsPublic />} />
      ))}
      {getLocalizedRoutes('meterReadingsPublicMultiple').map((path: string) => (
        <Route key={path} path={path} element={<MeterReadingsPublic />} />
      ))}
      {window?.brandConfig?.showCharts && (
        <>
          {getLocalizedRoutes('charts').map((path: string) => (
            <Route key={path} path={path} element={<Charts token={token} />} />
          ))}
        </>
      )}
      {getLocalizedRoutes('myLocations').map((path: string) => (
        <Route key={path} path={path} element={<MyLocationsPage token={token} />} />
      ))}
      {getLocalizedRoutes('financialDetails').map((path: string) => (
        <Route key={path} path={path} element={<InvoiceDetails token={token} />} />
      ))}
      {getLocalizedRoutes('financial').map((path: string) => (
        <Route key={path} path={path} element={<InvoiceList token={token} />} />
      ))}
      {getLocalizedRoutes('myData').map((path: string) => (
        <Route key={path} path={path} element={<MyData token={token} />} />
      ))}
      {getLocalizedRoutes('contracts').map((path: string) => (
        <Route key={path} path={path} element={<ContractList token={token} />} />
      ))}
      {getLocalizedRoutes('documents').map((path: string) => (
        <Route key={path} path={path} element={<Documents token={token} />} />
      ))}
      {getLocalizedRoutes('login').map((path: string) => (
        <Route key={path} path={path} element={<Login />} />
      ))}
      {getLocalizedRoutes('newPassword').map((path: string) => (
        <Route key={path} path={path} element={<NewPassword />} />
      ))}
      {getLocalizedRoutes('forgotPasswordStart').map((path: string) => (
        <Route key={path} path={path} element={<ForgotStart />} />
      ))}
      {getLocalizedRoutes('forgotPasswordFinish').map((path: string) => (
        <Route key={path} path={path} element={<ForgotFinish />} />
      ))}
      {getLocalizedRoutes('confirmAccount').map((path: string) => (
        <Route key={path} path={path} element={<ConfirmAccount />} />
      ))}
      {getLocalizedRoutes('createAccount').map((path: string) => (
        <Route key={path} path={path} element={<AccountCreation />} />
      ))}
      {getLocalizedRoutes('resendCode').map((path: string) => (
        <Route key={path} path={path} element={<ResendCodePage />} />
      ))}
      {getLocalizedRoutes('faq').map((path: string) => (
        <Route key={path} path={path} element={<FAQ token={token} />} />
      ))}
      {getLocalizedRoutes('terminationFee').map((path: string) => (
        <Route key={path} path={path} element={<TerminationFee token={token} />} />
      ))}
      {groups.includes('super_user') &&
        getLocalizedRoutes('terminationFeeCalculation').map((path: string) => (
          <Route key={path} path={path} element={<TerminationFeeCalculationPage token={token} />} />
        ))}
      {getLocalizedRoutes('referenceProposition').map((path: string) => (
        <Route key={path} path={path} element={<ReferenceProposition />} />
      ))}
      {getLocalizedRoutes('privacyPolicy').map((path: string) => (
        <Route key={path} path={path} element={<PrivacyPolicy />} />
      ))}
      {getLocalizedRoutes('contractRegistration').map((path: string) => (
        <Route key={path} path={path} element={<OrganizationRegistration token={token} />} />
      ))}
      {window?.brandConfig?.showAddendum && (
        <>
          {getLocalizedRoutes('addendum').map((path: string) => (
            <Route key={path} path={path} element={<Addendum />} />
          ))}
          {getLocalizedRoutes('addendumGreening').map((path: string) => (
            <Route key={path} path={path} element={<Greening token={token} />} />
          ))}
          {getLocalizedRoutes('addendumEntityChange').map((path: string) => (
            <Route key={path} path={path} element={<EntityChange token={token} />} />
          ))}
          {getLocalizedRoutes('addendumConsumptionChange').map((path: string) => (
            <Route key={path} path={path} element={<ConsumptionChange token={token} />} />
          ))}
          {getLocalizedRoutes('addendumMoving').map((path: string) => (
            <Route key={path} path={path} element={<Moving token={token} />} />
          ))}
          {getLocalizedRoutes('daughterAddendum').map((path: string) => (
            <Route key={path} path={path} element={<DaughterAddendum />} />
          ))}
          {getLocalizedRoutes('daughterAddendumGreening').map((path: string) => (
            <Route key={path} path={path} element={<DaughterGreening token={token} />} />
          ))}
          {getLocalizedRoutes('daughterAddendumEntityChange').map((path: string) => (
            <Route key={path} path={path} element={<DaughterEntityChange token={token} />} />
          ))}
          {getLocalizedRoutes('daughterAddendumConsumptionChange').map((path: string) => (
            <Route key={path} path={path} element={<DaughterConsumptionChange token={token} />} />
          ))}
          {getLocalizedRoutes('daughterAddendumMoving').map((path: string) => (
            <Route key={path} path={path} element={<DaughterMoving token={token} />} />
          ))}
        </>
      )}
      {groups.includes('admin') && getLocalizedRoutes('admin').map((path: string) => <Route key={path} path={path} element={<Toolset />} />)}
      <Route key="/" path="/" element={<Dashboard token={token} />} />
      <Route key="*" path="*" element={<NotFound token={token} />} />
    </Routes>
  )
}

export default Router
