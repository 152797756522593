import { FunctionComponent } from 'react'
import LayoutBase from '../components/LayoutBase'
import { Helmet } from 'react-helmet-async'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'
import { Col, Row } from 'antd'
import useLanguage from '../hooks/useLanguage'
import { Addendum as AddendumComponent } from '@myportal/ecedo-myportal-frontend-components'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../store/reducers'
import { OrganizationState } from '../store/reducers/organizationReducer.ts'

const DaughterAddendum: FunctionComponent = () => {
  const { language } = useLanguage()
  const { daughterOrganization } = useSelector<RootState, OrganizationState>((state) => state.organization)
  const navigate = useNavigate()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.contracts}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} lg={8}>
          <AddendumComponent
            handleSelectGreening={(e) => {
              e.preventDefault()
              navigate(`${getLocalizedRoutesForHistoryPush('addendumGreening')}/${daughterOrganization.organizationReference}`)
            }}
            handleSelectEntityChange={(e) => {
              e.preventDefault()
              navigate(`${getLocalizedRoutesForHistoryPush('addendumEntityChange')}/${daughterOrganization.organizationReference}`)
            }}
            handleSelectVolumeChangeOfSolarPanels={(e) => {
              e.preventDefault()
              navigate(`${getLocalizedRoutesForHistoryPush('addendumConsumptionChange')}/${daughterOrganization.organizationReference}`)
            }}
            language={language}
          />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default DaughterAddendum
