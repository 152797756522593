import { FunctionComponent } from 'react'
import LayoutBase from '../components/LayoutBase'
import { Helmet } from 'react-helmet-async'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'
import { Col, Row } from 'antd'
import useLanguage from '../hooks/useLanguage'
import { Addendum as AddendumComponent } from '@myportal/ecedo-myportal-frontend-components'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../store/reducers'
import { OrganizationState } from '../store/reducers/organizationReducer.ts'

const Addendum: FunctionComponent = () => {
  const { language } = useLanguage()
  const { parent } = useSelector<RootState, OrganizationState>((state) => state.organization)
  const navigate = useNavigate()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.contracts}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} lg={8}>
          <AddendumComponent
            handleSelectGreening={(e) => {
              e.preventDefault()
              navigate(getLocalizedRoutesForHistoryPush('addendumGreening'))
            }}
            handleSelectEntityChange={(e) => {
              e.preventDefault()
              navigate(getLocalizedRoutesForHistoryPush('addendumEntityChange'))
            }}
            handleSelectVolumeChangeOfSolarPanels={(e) => {
              e.preventDefault()
              navigate(getLocalizedRoutesForHistoryPush('addendumConsumptionChange'))
            }}
            handleSelectMoving={(e) => {
              e.preventDefault()
              navigate(getLocalizedRoutesForHistoryPush('addendumMoving'))
            }}
            language={language}
            isParent={parent}
          />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default Addendum
