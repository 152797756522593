import { SupportedLanguages } from '@myportal/ecedo-myportal-frontend-components'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../store/reducers'
import { LanguageState, changeActiveLanguage } from '../store/reducers/languageReducer'

interface LanguageHookReturn {
  language: SupportedLanguages
  changeLanguage: () => void
}

const useLanguage = (): LanguageHookReturn => {
  const dispatch = useDispatch()
  const { activeLanguage } = useSelector<RootState, LanguageState>((state) => state.language)

  const changeLanguage = useCallback(async () => {
    dispatch(changeActiveLanguage(activeLanguage === 'nl' ? 'en' : 'nl'))
  }, [dispatch, activeLanguage])

  return { language: activeLanguage as SupportedLanguages, changeLanguage }
}

export default useLanguage
