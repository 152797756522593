import { FunctionComponent } from 'react'
import { Col, Row } from 'antd'
import { Helmet } from 'react-helmet-async'

import { ThemeGenerator, OrganizationTool } from '@myportal/ecedo-myportal-frontend-components'

import LayoutBase from '../components/LayoutBase.tsx'
import { localizedRoutes } from '../utils'

const AdminPage: FunctionComponent = () => {
  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.admin}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24}>
          <ThemeGenerator />
        </Col>
        <Col xs={24} lg={12}>
          <OrganizationTool />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default AdminPage
