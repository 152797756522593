import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
  token: string
  email: string
  userId: string
  organizationId: string
  groups: string[]
}

const initialState: AuthState = {
  token: '',
  email: '',
  userId: '',
  organizationId: '',
  groups: [],
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>): void {
      state.token = action.payload
    },
    setEmail(state, action: PayloadAction<string>): void {
      state.email = action.payload
    },
    setUserId(state, action: PayloadAction<string>): void {
      state.userId = action.payload
    },
    setOrganizationId(state, action: PayloadAction<string>): void {
      state.organizationId = action.payload
    },
    setGroups(state, action: PayloadAction<string[]>): void {
      state.groups = action.payload
    },
    resetAuthState(state): void {
      state.token = initialState.token
      state.email = initialState.email
      state.userId = initialState.userId
      state.organizationId = initialState.organizationId
      state.groups = initialState.groups
    },
  },
})

export const { setToken, setEmail, setUserId, setOrganizationId, setGroups, resetAuthState } = authSlice.actions

export type AuthAction = ReturnType<(typeof authSlice.actions)[keyof typeof authSlice.actions]>

export default authSlice.reducer
