import { Col, Row } from 'antd'
import { Addendum, AdvancePayment, ChartMarketPrice, FAQ, InvoicesList, Welcome } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import CardLogo from '../components/CardLogo'
import LayoutBase from '../components/LayoutBase'
import useLanguage from '../hooks/useLanguage'
import { RootState } from '../store/reducers'
import { OrganizationState } from '../store/reducers/organizationReducer'
import { getLocalizedRoutesForHistoryPush } from '../utils'
import { breakpoints } from '../config/responsive.ts'

interface DashboardProps {
  token: string
}

const FlexCol = styled(Col)`
  display: flex;
  align-items: stretch;
  .ant-card {
    flex: 1;
  }
`

const StyledCol = styled(Col)`
  @media (max-width: ${breakpoints.md}) {
    .ant-card-body {
      padding: 12px;
    }
  }
`

const Dashboard: FunctionComponent<DashboardProps> = ({ token }) => {
  const { language } = useLanguage()
  const { parent } = useSelector<RootState, OrganizationState>((state) => state.organization)
  const navigate = useNavigate()

  const handleSelectInvoice = useCallback(
    (invoiceId: string) => {
      navigate(getLocalizedRoutesForHistoryPush('financialDetails').replace(':invoiceId', invoiceId))
    },
    [navigate]
  )

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} lg={16}>
          <Welcome baseUrl={window.brandConfig.baseUrl} token={token} language={language} image="/brand/gulfman.jpg" />
        </Col>
        <StyledCol xs={24} lg={8}>
          <CardLogo />
        </StyledCol>
        {!parent && (
          <FlexCol xs={24} lg={8}>
            <AdvancePayment baseUrl={window.brandConfig.baseUrl} token={token} language={language} breakpoints={{ xs: 24, sm: 24, md: 24, lg: 24 }} />
          </FlexCol>
        )}
        <FlexCol xs={24} lg={!parent ? 16 : 24}>
          <InvoicesList baseUrl={window.brandConfig.baseUrl} token={token} language={language} selectInvoice={handleSelectInvoice} lastInvoice />
        </FlexCol>
        {window?.brandConfig?.showAddendum && (
          <FlexCol xs={24} lg={16}>
            <Addendum
              handleSelectGreening={(e) => {
                e.preventDefault()
                navigate(getLocalizedRoutesForHistoryPush('addendumGreening'))
              }}
              handleSelectEntityChange={(e) => {
                e.preventDefault()
                navigate(getLocalizedRoutesForHistoryPush('addendumEntityChange'))
              }}
              handleSelectVolumeChangeOfSolarPanels={(e) => {
                e.preventDefault()
                navigate(getLocalizedRoutesForHistoryPush('addendumConsumptionChange'))
              }}
              handleSelectMoving={(e) => {
                e.preventDefault()
                navigate(getLocalizedRoutesForHistoryPush('addendumMoving'))
              }}
              language={language}
              isParent={parent}
            />
          </FlexCol>
        )}
        <Col xs={24}>
          <ChartMarketPrice baseUrl={window.brandConfig.baseUrl} token={token} language={language} labels={window.brandConfig.chartLabels} />
        </Col>
        {window.brandConfig.showFAQOnDashboard && (
          <Col xs={24}>
            <FAQ baseUrl={window.brandConfig.baseUrl} token={token} language={language} />
          </Col>
        )}
      </Row>
    </LayoutBase>
  )
}

export default Dashboard
