import { FunctionComponent } from 'react'
import LayoutBase from '../components/LayoutBase'
import { Helmet } from 'react-helmet-async'
import { localizedRoutes } from '../utils'
import { Col, Row } from 'antd'
import { AddendumEntityChange } from '@myportal/ecedo-myportal-frontend-components'
import useLanguage from '../hooks/useLanguage'

interface EntityChangeProps {
  token: string
}

const EntityChange: FunctionComponent<EntityChangeProps> = ({ token }) => {
  const { language } = useLanguage()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.addendumEntityChange}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={24}>
          <AddendumEntityChange baseUrl={window.brandConfig.baseUrl} token={token} language={language} />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default EntityChange
