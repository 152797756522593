import { Col, Row } from 'antd'
import { OrganizationRegistration as OrganizationRegistrationComponent } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

import LayoutBase from '../components/LayoutBase'
import { RootState } from '../store/reducers'
import { AuthState } from '../store/reducers/authReducer'
import { localizedRoutes } from '../utils'

interface OrganizationRegistrationProps {
  token: string
}

const OrganizationRegistration: FunctionComponent<OrganizationRegistrationProps> = ({ token }) => {
  const { email, userId } = useSelector<RootState, AuthState>((state) => state.auth)

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.contractRegistration}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24}>
          <OrganizationRegistrationComponent baseUrl={window.brandConfig.baseUrl} token={token} userId={userId} userEmail={email} />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default OrganizationRegistration
