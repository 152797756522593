import { FunctionComponent } from 'react'
import LayoutBase from '../components/LayoutBase.tsx'
import { Helmet } from 'react-helmet-async'
import { localizedRoutes } from '../utils'
import { Col, Row } from 'antd'

import useLanguage from '../hooks/useLanguage.ts'
import AddendumMoving from '@myportal/ecedo-myportal-frontend-components/components/myportal/AddendumMoving'

interface MovingProps {
  token: string
}

const Moving: FunctionComponent<MovingProps> = ({ token }) => {
  const { language } = useLanguage()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.addendumMoving}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={24}>
          <AddendumMoving baseUrl={window.brandConfig.baseUrl} token={token} language={language} />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default Moving
