import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface LanguageState {
  activeLanguage: string
}

const initialState: LanguageState = {
  activeLanguage: window.brandConfig.language?.includes('en') ? 'en' : 'nl',
}

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeActiveLanguage(state, action: PayloadAction<string>): void {
      state.activeLanguage = action.payload
    },
  },
})

export const { changeActiveLanguage } = languageSlice.actions

export type LanguageAction = ReturnType<(typeof languageSlice.actions)[keyof typeof languageSlice.actions]>

export default languageSlice.reducer
