import {
  DashboardOutlined,
  FileTextOutlined,
  LogoutOutlined,
  BookOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  BuildOutlined,
  AppstoreOutlined,
  HomeOutlined,
  BarChartOutlined,
  AppstoreAddOutlined,
  CalculatorOutlined,
  FileDoneOutlined,
  PaperClipOutlined,
} from '@ant-design/icons'

import translations, { SupportedLanguages } from '../translation'
import { getLocalizedRoutesForHistoryPush } from '../utils'
import { ItemType } from 'antd/es/menu/hooks/useItems'

export const defaultMenuList = (language: SupportedLanguages, groups: string[]): ItemType[] => {
  const menuList = [
    {
      icon: <HomeOutlined />,
      label: translations[language].general,
      key: '/',
      disabled: false,
    },
    {
      icon: <FileTextOutlined />,
      label: translations[language].financial,
      key: getLocalizedRoutesForHistoryPush('financial'),
      disabled: false,
    },
    {
      icon: <UserOutlined />,
      label: translations[language].myData,
      key: getLocalizedRoutesForHistoryPush('myData'),
      disabled: false,
    },
    {
      icon: <BookOutlined />,
      label: translations[language].contracts,
      key: getLocalizedRoutesForHistoryPush('contracts'),
      disabled: false,
    },
    {
      icon: <DashboardOutlined />,
      label: translations[language].myMeterReadings,
      key: getLocalizedRoutesForHistoryPush('meterReadings'),
      disabled: false,
    },
    {
      icon: <PaperClipOutlined />,
      label: translations[language].documents,
      key: getLocalizedRoutesForHistoryPush('documents'),
      disabled: false,
    },
  ]

  if (window?.brandConfig?.showCharts) {
    menuList.push({
      icon: <BarChartOutlined />,
      label: translations[language].consumption,
      key: getLocalizedRoutesForHistoryPush('charts'),
      disabled: false,
    })
  }

  if (window?.brandConfig?.showFAQ) {
    menuList.push({
      icon: <QuestionCircleOutlined />,
      label: translations[language].faq,
      key: getLocalizedRoutesForHistoryPush('faq'),
      disabled: false,
    })
  }

  if (window?.brandConfig?.showOrganizationListSelect) {
    menuList.push({
      icon: <AppstoreAddOutlined />,
      label: translations[language].contractRegistration,
      key: getLocalizedRoutesForHistoryPush('contractRegistration'),
      disabled: false,
    })

    if (groups.includes('super_user')) {
      menuList.push({
        icon: <CalculatorOutlined />,
        label: translations[language].terminationFeeCalculation,
        key: getLocalizedRoutesForHistoryPush('terminationFeeCalculation'),
        disabled: false,
      })
    }
  }

  if (window?.brandConfig?.showAddendum) {
    menuList.push({
      icon: <FileDoneOutlined />,
      label: translations[language].addendum,
      key: getLocalizedRoutesForHistoryPush('addendum'),
      disabled: false,
    })
  }

  menuList.push({
    icon: <LogoutOutlined />,
    label: translations[language].logout,
    key: '/logout',
    disabled: false,
  })

  return menuList
}

export const parentMenuList = (language: SupportedLanguages): ItemType[] => {
  const menuList = [
    {
      icon: <HomeOutlined />,
      label: translations[language].general,
      key: '/',
      disabled: false,
    },
    {
      icon: <BuildOutlined />,
      label: translations[language].myLocations,
      key: getLocalizedRoutesForHistoryPush('myLocations'),
      disabled: false,
    },
    {
      icon: <FileTextOutlined />,
      label: translations[language].financial,
      key: getLocalizedRoutesForHistoryPush('financial'),
      disabled: false,
    },
    {
      icon: <UserOutlined />,
      label: translations[language].myData,
      key: getLocalizedRoutesForHistoryPush('myData'),
      disabled: false,
    },
  ]

  if (window?.brandConfig?.showCharts) {
    menuList.push({
      icon: <BarChartOutlined />,
      label: translations[language].consumption,
      key: getLocalizedRoutesForHistoryPush('charts'),
      disabled: false,
    })
  }

  if (window?.brandConfig?.showFAQ) {
    menuList.push({
      icon: <QuestionCircleOutlined />,
      label: translations[language].faq,
      key: getLocalizedRoutesForHistoryPush('faq'),
      disabled: false,
    })
  }

  if (window?.brandConfig?.showAddendum) {
    menuList.push({
      icon: <FileDoneOutlined />,
      label: translations[language].addendum,
      key: getLocalizedRoutesForHistoryPush('addendum'),
      disabled: false,
    })
  }

  menuList.push({
    icon: <LogoutOutlined />,
    label: translations[language].logout,
    key: '/logout',
    disabled: false,
  })

  return menuList
}

export const descendantMenuList = (language: SupportedLanguages, organizationId: string): ItemType[] => {
  const menuList = [
    {
      icon: <HomeOutlined />,
      label: translations[language].general,
      key: `${getLocalizedRoutesForHistoryPush('general')}/${organizationId}`,
      disabled: false,
    },
    {
      icon: <AppstoreOutlined />,
      label: translations[language].connections,
      key: `${getLocalizedRoutesForHistoryPush('connections')}/${organizationId}`,
      disabled: false,
    },
  ]

  if (window?.brandConfig?.showAddendum) {
    menuList.push({
      icon: <FileDoneOutlined />,
      label: translations[language].addendum,
      key: `${getLocalizedRoutesForHistoryPush('addendum')}/${organizationId}`,
      disabled: false,
    })
  }

  return menuList
}
