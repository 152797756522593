import { LeftOutlined } from '@ant-design/icons'
import { TerminationFee as TerminationFeeComponent } from '@myportal/ecedo-myportal-frontend-components'
import { Button, Col, Row } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'

import LayoutBase from '../components/LayoutBase'
import useLanguage from '../hooks/useLanguage'
import translations from '../translation'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'
import { ProductType } from '@myportal/ecedo-myportal-frontend-components/interfaces/ProductType'

interface TerminationFeeProps {
  token: string
}

const TerminationFee: FunctionComponent<TerminationFeeProps> = ({ token }) => {
  const { language } = useLanguage()
  const navigate = useNavigate()
  const { contractId, product } = useParams()

  const navigateBack = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      navigate(-1)
    },
    [navigate]
  )

  const navigateToReferencePropositionScreen = useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault()
      navigate(
        getLocalizedRoutesForHistoryPush('referenceProposition')
          .replace(':contractId', contractId as string)
          .replace(':product', product as ProductType)
      )
    },
    [navigate, contractId, product]
  )

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.terminationFee}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={24}>
          <TerminationFeeComponent
            baseUrl={window.brandConfig.baseUrl}
            token={token}
            contractId={contractId as string}
            navigateToReferencePropositionScreen={navigateToReferencePropositionScreen}
            product={product as ProductType}
            language={language}
            showCalculationTable={window.brandConfig.showTerminationFeeCalculationTable}
          />
        </Col>
        <Col xs={24} md={24}>
          <Button icon={<LeftOutlined />} onClick={navigateBack}>
            {translations[language].goBackTo} {translations[language].previousPage}
          </Button>
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default TerminationFee
