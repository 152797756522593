import { FunctionComponent, useCallback } from 'react'
import { OrganizationCard, OrganizationSelect } from '@myportal/ecedo-myportal-frontend-components'
import { Divider, Menu, Spin } from 'antd'
import { descendantMenuList } from './MenuList.tsx'
import translations from '../translation'
import { SettingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Organization, resetOrganizationState, setBreadcrumb } from '../store/reducers/organizationReducer.ts'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { resetAuthState, setOrganizationId, setToken } from '../store/reducers/authReducer.ts'
import { getLocalizedRoutesForHistoryPush } from '../utils'
import { useSWRConfig } from 'swr'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import useLanguage from '../hooks/useLanguage.ts'

const LogoContainer = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    max-width: 150px;
    max-height: 50px;
    object-fit: contain;
  }
`

const DescendantContainer = styled.div`
  background-color: white;
  border-top: 3px solid #f0f2f5;
  border-bottom: 3px solid #f0f2f5;
  margin: 6px 0;
`

interface NavBarProps {
  selected: boolean
  token: string
  userId: string
  organizationId: string
  pendingOrganization: boolean
  pendingDaughterOrganizationList: boolean
  mainMenuList: ItemType[]
  daughterOrganization: Organization
  handleClose: () => void
  groups: string[]
}

const NavBar: FunctionComponent<NavBarProps> = ({
  selected,
  token,
  userId,
  organizationId,
  pendingOrganization,
  pendingDaughterOrganizationList,
  mainMenuList,
  daughterOrganization,
  handleClose,
  groups,
}) => {
  const { mutate } = useSWRConfig()
  const { language } = useLanguage()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleAdminMenuClick = useCallback((item: { key: string }) => {
    window.location.href = item.key
  }, [])

  const handleDescendantMenuClick = useCallback(
    (item: { key: string }) => {
      navigate(item.key)
      const active = item.key.includes('general') ? 2 : 3
      dispatch(setBreadcrumb({ active }))
    },
    [navigate, dispatch]
  )

  const clearCache = () => mutate(() => true, undefined, { revalidate: false })

  const handleMenuClick = useCallback(
    async (item: { key: string }) => {
      if (item.key === '/logout') {
        try {
          await Auth.signOut()
          dispatch(resetAuthState())
          dispatch(resetOrganizationState())
          clearCache()
          window.location.href = window.brandConfig.logoutRedirectUrl ? window.brandConfig.logoutRedirectUrl : getLocalizedRoutesForHistoryPush('login')
        } catch (e) {
          console.error(e)
        }
      } else {
        navigate(item.key)
      }
    },
    [dispatch, navigate, clearCache]
  )

  const handleChangeOrganization = useCallback(
    ({ token, organizationId }: { token: string; organizationId: string }): void => {
      dispatch(setToken(token))
      dispatch(setOrganizationId(organizationId))
      clearCache()
      window.location.reload()
    },
    [dispatch]
  )

  return (
    <>
      <LogoContainer>
        <img src="/brand/brand.logo.svg" alt={window.brandConfig.name || 'Logo'} />
      </LogoContainer>
      {!selected ? (
        <>
          <OrganizationSelect
            baseUrl={window.brandConfig.baseUrl}
            token={token}
            showOrganizationListSelect={window.brandConfig.showOrganizationListSelect}
            userId={userId}
            selectedOrganizationId={organizationId}
            handleChangeOrganization={handleChangeOrganization}
          />
          <Spin spinning={pendingOrganization || pendingDaughterOrganizationList}>
            <Menu mode="inline" selectedKeys={[location.pathname]} onClick={handleMenuClick} items={mainMenuList} />
          </Spin>
        </>
      ) : (
        <DescendantContainer>
          <OrganizationCard
            number={daughterOrganization?.organizationReference || ''}
            name={daughterOrganization?.organizationName || ''}
            show={!!daughterOrganization}
            icon
            handleClose={handleClose}
          />
          <Menu
            mode="inline"
            selectedKeys={[location.pathname]}
            onClick={handleDescendantMenuClick}
            items={descendantMenuList(language, daughterOrganization?.organizationReference || '')}
          />
        </DescendantContainer>
      )}
      {groups.includes('admin') && (
        <>
          <Divider />
          <Menu
            mode="inline"
            selectedKeys={[location.pathname]}
            onClick={handleAdminMenuClick}
            items={[{ label: translations[language as keyof typeof translations].admin, key: '/admin', icon: <SettingOutlined /> }]}
          />
        </>
      )}
    </>
  )
}

export default NavBar
