import { NewPasswordForm } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import LayoutCenter from '../components/LayoutCenter'
import useLanguage from '../hooks/useLanguage'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'

const NewPasswordPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const { language } = useLanguage()

  const navigateToLogin = useCallback(() => {
    navigate(getLocalizedRoutesForHistoryPush('login'))
  }, [navigate])

  return (
    <LayoutCenter>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.newPassword}`} />
        </Helmet>
      )}
      <NewPasswordForm language={language} navigateToLogin={navigateToLogin} />
    </LayoutCenter>
  )
}

export default NewPasswordPage
