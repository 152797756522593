export const localizedRoutes = {
  nl: {
    connections: '/verbindingen',
    contracts: '/contracten',
    charts: '/grafieken',
    confirmAccount: '/bevestigen',
    createAccount: '/registreren',
    daughterConnections: '/verbindingen/:organizationReference',
    daughterContracts: '/contracten/:organizationReference',
    daughterData: '/algemeen/:organizationReference',
    daughterFinancial: '/financieel/:organizationReference',
    daughterMeterReadings: '/meterstanden/:organizationReference',
    daughterAddendum: '/verzoek-om-gegevensmutatie/:organizationReference',
    daughterAddendumGreening: '/verzoek-om-gegevensmutatie/vergroening/:organizationReference',
    daughterAddendumEntityChange: '/verzoek-om-gegevensmutatie/entiteitswijziging/:organizationReference',
    daughterAddendumConsumptionChange: '/verzoek-om-gegevensmutatie/volumewijziging/:organizationReference',
    daughterAddendumMoving: '/verzoek-om-gegevensmutatie/verhuizing/:organizationReference',
    documents: '/documents',
    faq: '/faq',
    financial: '/financieel',
    financialDetails: '/financieel-details/:invoiceId',
    forgotPasswordFinish: '/wachtwoord-bevestigen',
    forgotPasswordStart: '/wachtwoord',
    general: '/algemeen',
    login: '/inloggen',
    meterReadings: '/meterstanden',
    meterReadingsPublic: '/meterstanden-invoeren/:organizationId/:connectionGroupId',
    meterReadingsPublicMultiple: '/meterstanden-invoeren/:organizationId',
    myLocations: '/mijn-locaties',
    myData: '/mijn-data',
    newPassword: '/nieuw-wachtwoord',
    privacyPolicy: '/privacybeleid',
    resendCode: '/code-nogmaals-versturen',
    referenceProposition: '/referentie-product/:contractId/:product',
    terminationFee: '/opzegvergoeding/:contractId/:product',
    terminationFeeCalculation: '/berekening-opzegvergoeding',
    contractRegistration: '/klantnummer-toevoegen',
    addendum: '/verzoek-om-gegevensmutatie',
    addendumGreening: '/verzoek-om-gegevensmutatie/vergroening',
    addendumEntityChange: '/verzoek-om-gegevensmutatie/contractovername',
    addendumConsumptionChange: '/verzoek-om-gegevensmutatie/volumewijziging',
    addendumMoving: '/verzoek-om-gegevensmutatie/verhuizing',
    admin: '/admin',
  },
  en: {
    charts: '/charts',
    connections: '/connections',
    contracts: '/contracts',
    confirmAccount: '/confirm-account',
    createAccount: '/create-account',
    daughterConnections: '/connections/:organizationReference',
    daughterContracts: '/contracts/:organizationReference',
    daughterData: '/general/:organizationReference',
    daughterFinancial: '/financial/:organizationReference',
    daughterMeterReadings: '/meter-readings/:organizationReference',
    daughterAddendum: '/data-mutation-request/:organizationReference',
    daughterAddendumGreening: '/data-mutation-request/greening/:organizationReference',
    daughterAddendumEntityChange: '/data-mutation-request/entity-change/:organizationReference',
    daughterAddendumConsumptionChange: '/data-mutation-request/volume-change/:organizationReference',
    daughterAddendumMoving: '/data-mutation-request/moving/:organizationReference',
    documents: '/documenten',
    general: '/general',
    faq: '/faq',
    financial: '/financial',
    financialDetails: '/financial-details/:invoiceId',
    forgotPasswordFinish: '/forgot-password-finish',
    forgotPasswordStart: '/forgot-password',
    login: '/login',
    meterReadings: '/meter-readings',
    meterReadingsPublic: '/enter-meter-readings/:organizationId/:connectionGroupId',
    meterReadingsPublicMultiple: '/enter-meter-readings/:organizationId',
    myLocations: '/my-locations',
    myData: '/my-data',
    newPassword: '/new-password',
    privacyPolicy: '/privacy-policy',
    resendCode: '/resend-code',
    referenceProposition: '/reference-proposition/:contractId/:product',
    terminationFee: '/termination-fee/:contractId/:product',
    terminationFeeCalculation: '/termination-fee-calculation',
    contractRegistration: '/contract-registration',
    addendum: '/data-mutation-request',
    addendumGreening: '/data-mutation-request/greening',
    addendumEntityChange: '/data-mutation-request/contract-takeover',
    addendumConsumptionChange: '/data-mutation-request/volume-change',
    addendumMoving: '/data-mutation-request/moving',
    admin: '/admin',
  },
}

export type LocalizedRouteLanguageType = keyof typeof localizedRoutes
export type LocalizedRouteType = keyof (typeof localizedRoutes)[LocalizedRouteLanguageType]

export const getLocalizedRoutes = (type: LocalizedRouteType): string[] =>
  window.brandConfig.languageUrl === 'both' || !window.brandConfig.languageUrl
    ? [localizedRoutes.nl[type], localizedRoutes.en[type]]
    : [localizedRoutes[(window.brandConfig.languageUrl || 'en') as LocalizedRouteLanguageType][type]]

export const getLocalizedRoutesForHistoryPush = (type: LocalizedRouteType): string =>
  window.brandConfig.languageUrl === 'both' ? getLocalizedRoutes(type)[0] : (getLocalizedRoutes(type)[0] as string)
