const nl = {
  general: 'Algemeen',
  connections: 'Aansluitingen',
  financial: 'Financieel',
  myData: 'Mijn gegevens',
  contracts: 'Contracten',
  documents: 'Documenten',
  myMeterReadings: 'Mijn meterstanden',
  meterReadings: 'Meterstanden',
  logout: 'Uitloggen',
  faq: 'FAQ',
  myLocations: 'Mijn locaties',
  consumption: 'Verbruik',
  pageNotFound: 'Pagina niet gevonden.',
  goBackTo: 'Ga terug naar',
  dashboard: 'het dashboard',
  login: 'de inlogpagina',
  admin: 'Admin pagina',
  previousPage: 'Vorige pagina',
  contractRegistration: 'Klantnummer toevoegen',
  terminationFeeCalculation: 'Berekening opzegvergoeding',
  addendum: 'Verzoek om gegevensmutatie',
}

export type nlType = typeof nl

export default nl
