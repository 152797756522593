import { Auth } from 'aws-amplify'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'

import { RootState } from '../store/reducers'
import { AuthState, resetAuthState, setEmail, setGroups, setOrganizationId, setToken, setUserId } from '../store/reducers/authReducer'
import { fetchDaughterOrganizationList, fetchOrganization, OrganizationState, resetOrganizationState } from '../store/reducers/organizationReducer'
import { localizedRoutes } from '../utils'

const protectedRoutes: string[] = [
  '/',
  localizedRoutes.en.connections,
  localizedRoutes.nl.connections,
  localizedRoutes.en.contracts,
  localizedRoutes.nl.contracts,
  localizedRoutes.en.charts,
  localizedRoutes.nl.charts,
  localizedRoutes.en.faq,
  localizedRoutes.nl.faq,
  localizedRoutes.en.financial,
  localizedRoutes.nl.financial,
  localizedRoutes.en.financialDetails.substring(0, 18),
  localizedRoutes.nl.financialDetails.substring(0, 19),
  localizedRoutes.en.general,
  localizedRoutes.nl.general,
  localizedRoutes.en.meterReadings,
  localizedRoutes.nl.meterReadings,
  localizedRoutes.en.myLocations,
  localizedRoutes.nl.myLocations,
  localizedRoutes.en.myData,
  localizedRoutes.nl.myData,
  localizedRoutes.en.terminationFee.substring(0, 16),
  localizedRoutes.nl.terminationFee.substring(0, 16),
  localizedRoutes.en.terminationFeeCalculation,
  localizedRoutes.nl.terminationFeeCalculation,
  localizedRoutes.en.contractRegistration,
  localizedRoutes.nl.contractRegistration,
  localizedRoutes.en.admin,
  localizedRoutes.nl.admin,
]

const loggedOutRoutes: string[] = [
  localizedRoutes.en.confirmAccount,
  localizedRoutes.nl.confirmAccount,
  localizedRoutes.en.createAccount,
  localizedRoutes.nl.createAccount,
  localizedRoutes.en.resendCode,
  localizedRoutes.nl.resendCode,
  localizedRoutes.en.forgotPasswordFinish,
  localizedRoutes.nl.forgotPasswordFinish,
  localizedRoutes.en.forgotPasswordStart,
  localizedRoutes.nl.forgotPasswordStart,
  localizedRoutes.en.meterReadingsPublic.substring(0, 21),
  localizedRoutes.nl.meterReadingsPublic.substring(0, 22),
  localizedRoutes.en.login,
  localizedRoutes.nl.login,
  localizedRoutes.en.newPassword,
  localizedRoutes.nl.newPassword,
]

const bothRoutes: string[] = [
  localizedRoutes.en.privacyPolicy,
  localizedRoutes.nl.privacyPolicy,
  localizedRoutes.en.referenceProposition.substring(0, 22),
  localizedRoutes.nl.referenceProposition.substring(0, 19),
]

interface AuthHookReturn {
  token: string
}

const useAuth = (): AuthHookReturn => {
  const { mutate } = useSWRConfig()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = useSelector<RootState, AuthState>((state) => state.auth)
  const { organization } = useSelector<RootState, OrganizationState>((state) => state.organization)
  const actualPath = location.pathname

  useEffect(() => {
    const clearCache = async () => mutate(() => true, undefined, { revalidate: false })

    const eventCallback = async (): Promise<void> => {
      await Auth.signOut()
      dispatch(resetAuthState())
      dispatch(resetOrganizationState())
      await clearCache()
      navigate('/login')
    }
    document.addEventListener('unauthorizedAPIError', eventCallback)
    return (): void => document.removeEventListener('unauthorizedAPIError', eventCallback)
  }, [dispatch, navigate, mutate])

  const handleLogin = useCallback(async () => {
    try {
      const data = await Auth.currentSession()
      const currentToken = data.getIdToken().getJwtToken()
      if (token !== currentToken) {
        dispatch(setToken(currentToken))
        dispatch(setEmail(data.getIdToken().payload.email))
        dispatch(setUserId(data.getIdToken().payload['cognito:username']))
        dispatch(setOrganizationId(data.getIdToken().payload['custom:OrganizationID']))
        dispatch(setGroups(data.getAccessToken().payload['cognito:groups'] ?? []))
        if (!organization?.organizationId) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(fetchOrganization(currentToken))
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(fetchDaughterOrganizationList(currentToken))
        }
        localStorage.clear()
      }
      if (loggedOutRoutes.includes(actualPath)) {
        navigate('/')
      }
    } catch (e) {
      if (
        protectedRoutes.some((item) => actualPath.includes(item)) &&
        !loggedOutRoutes.some((item) => actualPath.includes(item)) &&
        !bothRoutes.some((item) => actualPath.includes(item))
      ) {
        navigate('login')
      }
    }
  }, [dispatch, actualPath, navigate, token, organization])

  useEffect(() => {
    handleLogin()
    const refreshTokenIntervalId = setInterval(() => {
      handleLogin()
    }, 907500)
    return (): void => {
      if (refreshTokenIntervalId) clearInterval(refreshTokenIntervalId)
    }
  }, [handleLogin])

  return { token }
}
export default useAuth
