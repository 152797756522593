import { FunctionComponent } from 'react'
import LayoutBase from '../components/LayoutBase.tsx'
import { Col, Row } from 'antd'
import { TerminationFeeCalculation } from '@myportal/ecedo-myportal-frontend-components'
import { Helmet } from 'react-helmet-async'
import { localizedRoutes } from '../utils'
import { useSelector } from 'react-redux'
import { RootState } from '../store/reducers'
import { AuthState } from '../store/reducers/authReducer.ts'

interface TerminationFeeCalculationPageProps {
  token: string
}

const TerminationFeeCalculationPage: FunctionComponent<TerminationFeeCalculationPageProps> = ({ token }) => {
  const { email } = useSelector<RootState, AuthState>((state) => state.auth)

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.terminationFeeCalculation}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={24}>
          <TerminationFeeCalculation baseUrl={window.brandConfig.baseMyPortalBackendUrl} token={token} userEmail={email} />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default TerminationFeeCalculationPage
