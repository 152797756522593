const en = {
  general: 'General',
  connections: 'Connections',
  financial: 'Financial',
  myData: 'My data',
  contracts: 'Contracts',
  documents: 'Documents',
  myMeterReadings: 'My meter readings',
  meterReadings: 'Meter readings',
  logout: 'Logout',
  faq: 'FAQ',
  myLocations: 'My locations',
  consumption: 'Consumption',
  pageNotFound: 'Page not found!',
  goBackTo: 'Go back to',
  dashboard: 'the dashboard',
  login: 'the login page',
  admin: 'Admin page',
  previousPage: 'Previous page',
  contractRegistration: 'Contract registration',
  terminationFeeCalculation: 'Termination fee calculation',
  addendum: 'Data mutation request',
}

export type enType = typeof en

export default en
