import { ForgotPasswordFinishForm } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import Cookie from 'js-cookie'

import LayoutCenter from '../components/LayoutCenter'
import useLanguage from '../hooks/useLanguage'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'

const ForgotPasswordFinishPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const { language } = useLanguage()

  const navigateToLogin = useCallback(() => {
    Cookie.set('privacy-and-security-update', 'true', { expires: 400 })
    navigate(getLocalizedRoutesForHistoryPush('login'))
  }, [navigate])

  return (
    <LayoutCenter>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.forgotPasswordFinish}`} />
        </Helmet>
      )}
      <ForgotPasswordFinishForm language={language} navigateToNextScreen={navigateToLogin} />
    </LayoutCenter>
  )
}

export default ForgotPasswordFinishPage
