import { Col, Row } from 'antd'
import { AdvancePayment, FAQ, InvoicesList } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import LayoutBase from '../components/LayoutBase'
import useLanguage from '../hooks/useLanguage'
import { RootState } from '../store/reducers'
import { OrganizationState } from '../store/reducers/organizationReducer'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'

interface InvoiceList {
  token: string
}

const InvoiceList: FunctionComponent<InvoiceList> = ({ token }) => {
  const { language } = useLanguage()
  const { parent } = useSelector<RootState, OrganizationState>((state) => state.organization)
  const navigate = useNavigate()

  const handleSelectInvoice = useCallback(
    (invoiceId: string) => {
      navigate(getLocalizedRoutesForHistoryPush('financialDetails').replace(':invoiceId', invoiceId))
    },
    [navigate]
  )

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.financial}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        {!parent && (
          <Col xs={24} md={12} lg={8} xl={6}>
            <AdvancePayment baseUrl={window.brandConfig.baseUrl} token={token} language={language} breakpoints={{ xs: 24, sm: 24, md: 24, lg: 24 }} />
          </Col>
        )}
        <Col xs={24}>
          <InvoicesList baseUrl={window.brandConfig.baseUrl} token={token} language={language} selectInvoice={handleSelectInvoice} />
        </Col>
        <Col xs={24}>
          <FAQ baseUrl={window.brandConfig.baseUrl} token={token} language={language} page="financial" />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default InvoiceList
