import { Col, Row } from 'antd'
import { FAQ, MeterReadingsReport } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import LayoutCenter from '../components/LayoutCenter'
import useLanguage from '../hooks/useLanguage'
import { localizedRoutes } from '../utils'

const MeterReadingsPublic: FunctionComponent = () => {
  const { language } = useLanguage()
  const { organizationId, connectionGroupId } = useParams()

  return (
    <LayoutCenter top>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.meterReadings}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]}>
        <Col xs={24}>
          <MeterReadingsReport
            baseUrl={window.brandConfig.baseUrl}
            organizationId={organizationId}
            connectionGroupId={connectionGroupId}
            language={language}
            inline={!!connectionGroupId}
            unAuth
            multiple={!connectionGroupId}
            useEventDateForMeterReadingEntry={window.brandConfig.useEventDateForMeterReadingEntry}
          />
        </Col>
        <Col xs={24}>
          <FAQ baseUrl={window.brandConfig.baseUrl} token="" language={language} page="meterReadingsPublic" />
        </Col>
      </Row>
    </LayoutCenter>
  )
}

export default MeterReadingsPublic
