import { FunctionComponent } from 'react'
import LayoutBase from '../components/LayoutBase.tsx'
import { Helmet } from 'react-helmet-async'
import { localizedRoutes } from '../utils'
import { Col, Row } from 'antd'

import useLanguage from '../hooks/useLanguage.ts'
import { useSelector } from 'react-redux'
import { RootState } from '../store/reducers'
import { OrganizationState } from '../store/reducers/organizationReducer.ts'
import useDaughterOrganization from '../hooks/useDaughterOrganization.ts'
import AddendumMoving from '@myportal/ecedo-myportal-frontend-components/components/myportal/AddendumMoving'

interface MovingProps {
  token: string
}

const DaughterMoving: FunctionComponent<MovingProps> = ({ token }) => {
  const { language } = useLanguage()
  const { daughterOrganization } = useSelector<RootState, OrganizationState>((state) => state.organization)

  useDaughterOrganization()

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.daughterAddendumMoving}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={24}>
          <AddendumMoving baseUrl={window.brandConfig.baseUrl} token={token} language={language} organizationId={daughterOrganization?.organizationId} />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default DaughterMoving
