import { Col, Row } from 'antd'
import { ContractAndTariff, FAQ } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import LayoutBase from '../components/LayoutBase'
import useLanguage from '../hooks/useLanguage'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'

interface ContractList {
  token: string
}

const ContractList: FunctionComponent<ContractList> = ({ token }) => {
  const { language } = useLanguage()
  const navigate = useNavigate()

  const navigateToTerminationFeeScreen = useCallback(
    (contractId: string, product: string): void => {
      navigate(getLocalizedRoutesForHistoryPush('terminationFee').replace(':contractId', contractId).replace(':product', product))
    },
    [navigate]
  )

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.contracts}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24} md={24}>
          <ContractAndTariff
            baseUrl={window.brandConfig.baseUrl}
            token={token}
            language={language}
            navigateToTerminationFeeScreen={navigateToTerminationFeeScreen}
            priceColumn={{ xs: 1, sm: 1, md: 2, lg: 3 }}
            showInfoButton={window.brandConfig.showTerminationFeeInfoButton}
          />
        </Col>
        <Col xs={24}>
          <FAQ baseUrl={window.brandConfig.baseUrl} token={token} language={language} page="contracts" />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default ContractList
