import { MyConnections } from '@myportal/ecedo-myportal-frontend-components'
import { Col, Row } from 'antd'
import camelCase from 'lodash/camelCase'
import { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import LayoutBase from '../components/LayoutBase'
import useDaughterOrganization from '../hooks/useDaughterOrganization'
import useLanguage from '../hooks/useLanguage'
import { RootState } from '../store/reducers'
import { OrganizationState, setConnectionGroup } from '../store/reducers/organizationReducer'
import { getLocalizedRoutesForHistoryPush, localizedRoutes, LocalizedRouteType } from '../utils'

interface DaughterConnectionsProps {
  token: string
}

const DaughterConnections: FunctionComponent<DaughterConnectionsProps> = ({ token }) => {
  const { language } = useLanguage()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { daughterOrganization } = useSelector<RootState, OrganizationState>((state) => state.organization)

  useDaughterOrganization()

  const handleSelect = useCallback(
    (page: string, connectionGroup: any) => {
      dispatch(setConnectionGroup({ page, connectionGroup }))
      navigate(`${getLocalizedRoutesForHistoryPush(camelCase(page) as LocalizedRouteType)}/${daughterOrganization.organizationReference}`)
    },
    [daughterOrganization.organizationReference, dispatch, navigate]
  )

  return (
    <LayoutBase>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.daughterConnections}`} />
        </Helmet>
      )}
      <Row gutter={[16, 24]} align="stretch">
        <Col xs={24}>
          <MyConnections
            baseUrl={window.brandConfig.baseUrl}
            token={token}
            language={language}
            organizationId={daughterOrganization?.organizationId || ''}
            selectCallback={handleSelect}
          />
        </Col>
      </Row>
    </LayoutBase>
  )
}

export default DaughterConnections
