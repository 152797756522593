import { FunctionComponent } from 'react'
import styled from 'styled-components'

import CardStretched from './CardStretched'
import { breakpoints } from '../config/responsive.ts'

const FullHeightCard = styled(CardStretched)`
  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-height: 100px;
    object-fit: contain;
    @media (max-width: ${breakpoints.md}) {
      max-height: 75px;
    }
  }
`

const CardLogo: FunctionComponent = () => {
  return (
    <FullHeightCard>
      <img src="/brand/brand.logo.svg" alt={window.brandConfig.name || 'Logo'} />
    </FullHeightCard>
  )
}

export default CardLogo
